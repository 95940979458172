import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export const BASE_URL: string = environment.BASE_URL;
export const RESOURCE_URL = 'company-attachment';
export const RESOURCE_URL1 = 'retain-maintanace-attachment';
export const RESOURCE_URL2 = 'remain-maintanace-attachment';
export const RESOURCE_URL3 = 'add-maintanace-attachment';
export const RESOURCE_URL4 = 'delete-maintanace-attachment';
export const RESOURCE_URL5 = 'delete-principal-attachment';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

    attachments: Observable<any>;
    attachmentsSubject: BehaviorSubject<any>;
  
    private API_ENDPOINT = `${BASE_URL}/${RESOURCE_URL}`;
    private API_ENDPOINT1 = `${BASE_URL}/${RESOURCE_URL1}`;
    private API_ENDPOINT2 = `${BASE_URL}/${RESOURCE_URL2}`;
    private API_ENDPOINT3 = `${BASE_URL}/${RESOURCE_URL3}`;
    private API_ENDPOINT4 = `${BASE_URL}/${RESOURCE_URL4}`;
    private API_ENDPOINT5 = `${BASE_URL}/${RESOURCE_URL5}`;

    constructor(
        private httpClient: HttpClient,
      ) {
        this.attachmentsSubject = new BehaviorSubject<any>(this.get());
        this.attachments = this.attachmentsSubject.asObservable();
      }
    
      form = new FormGroup({
        id        : new FormControl(''),
        document_id : new FormControl('', [Validators.required]),
        attachment : new FormControl('', [Validators.required]),
        description : new FormControl(''),

      });
    
      /**
       * helper function to extract data since
       * we are not using a type checker in the request
       * @returns Observable
       *
       * @param res
       */
      private static extractData(res: Response) {
        return res || {};
      }
    
      /**
       * get the list of all attachments from the api
       * @param null null
       * @returns Observable
       *
       */
      get(): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT).pipe(
          map(AttachmentService.extractData));
      }
    
      /**
       * get a attachment by from the api
       * @param id : string
       * @returns Observable
       *
       */
      find(id): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT + '/' + id).pipe(
          map(AttachmentService.extractData));
      }
    
      /**
       * create a attachment
       * @param attachment : Object
       * @returns Observable
       *
       */
      create(attachment: any): Observable<any> {
        return this.httpClient.post<any>(this.API_ENDPOINT, attachment)
        .pipe(tap((attachment) => console.log(`added attachment w/ name=${attachment}`)),
          catchError(this.handleError<any>('create attachment'))
        );
      }

      addMaintananceAttachment(attachment: any): Observable<any> {
        return this.httpClient.post<any>(this.API_ENDPOINT3, attachment)
        .pipe(tap((attachment) => console.log(`added attachment w/ name=${attachment}`)),
          catchError(this.handleError<any>('create attachment'))
        );
      }
    
      /**
       * delete a attachment
       * @param id : string
       * @returns Observable
       *
       */
      delete(id): Observable<any> {
        return this.httpClient.delete<any>(this.API_ENDPOINT + '/' + id).pipe(
          tap(_ => console.log(`deleted attachment id=${id}`)),
          catchError(this.handleError<any>('delete attachment'))
        );
      }
    
      /**
       * update a attachment
       * @param attachment : Object
       * @returns Observable
       *
       */
      update(attachment): Observable<any> {
        return this.httpClient.put(this.API_ENDPOINT + '/' + attachment.id, attachment)
        .pipe(tap(response => console.log(`updated attachment id=${attachment.id}`)),
          catchError(this.handleError<any>('update attachment'))
        );
      }

      retainedMaintanaceAttachments(): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT1).pipe(
          map(AttachmentService.extractData));
      }

      remainMaintanaceAttachments(): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT2);
      }

      deleteMaintananceAttachment(id): Observable<any> {
        return this.httpClient.delete<any>(this.API_ENDPOINT4 + '/' + id).pipe(
          tap(_ => console.log(`deleted attachment id=${id}`)),
          catchError(this.handleError<any>('delete attachment'))
        );
      }

      deletePrincilaAttachment(id): Observable<any> {
        return this.httpClient.delete<any>(this.API_ENDPOINT5 + '/' + id).pipe(
          tap(_ => console.log(`deleted attachment id=${id}`)),
          catchError(this.handleError<any>('delete attachment'))
        );
      }
    
      private getValue() {
        return 0;
      }
    
      /**
       * Error handler function that handles all the errors
       * @returns Observable
       *
       * @param operation
       * @param result
       */
      private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
    
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
    
          // TODO: better job of transforming error for attachment consumption
          console.log(`${operation} failed: ${error.message}`);
    
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }
    
    
      initializeFormGroup() {
        return this.form.setValue({
          id        : '',
          document_id : '',
          attachment : '',
          description : '',
        });
      }
    
      populateForm(data) {
        this.form.patchValue(data);
      }
    
      compareObjects(o1, o2) {
        return o1 && o2 && o1.id === o2.id;
      }
}
