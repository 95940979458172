import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export const BASE_URL: string = environment.BASE_URL;
export const RESOURCE_URL = 'document';
export const RESOURCE_URL2 = 'get-remaining-docs';
export const RESOURCE_URL3 = 'get-renewal-docs';
export const RESOURCE_URL4 = 'get-add-principal-company-docs';
export const RESOURCE_URL5 = 'get-change-principal-company-docs';
export const RESOURCE_URL6 = 'remaining-principal-company-documents';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  
  patch(data: any) {
    throw new Error('Method not implemented.');
  }

  document: Observable<any>;
  documentSubject: BehaviorSubject<any>;
  
    private API_ENDPOINT = `${BASE_URL}/${RESOURCE_URL}`;
    private API_ENDPOINT2 = `${BASE_URL}/${RESOURCE_URL2}`;
    private API_ENDPOINT3 = `${BASE_URL}/${RESOURCE_URL3}`;
    private API_ENDPOINT4 = `${BASE_URL}/${RESOURCE_URL4}`;
    private API_ENDPOINT5 = `${BASE_URL}/${RESOURCE_URL5}`;
    private API_ENDPOINT6 = `${BASE_URL}/${RESOURCE_URL6}`;


  constructor(private httpClient: HttpClient,) {

    this.documentSubject = new BehaviorSubject<any>(this.get());
    this.document = this.documentSubject.asObservable();
   }

   form = new FormGroup({
    id        : new FormControl(''),
    document_name       : new FormControl(''),
    player_category_id : new FormControl([], [Validators.required]),
    is_business_licence  : new FormControl('', [Validators.required]),
    document_information : new FormControl(''),
    is_active : new FormControl('', [Validators.required]),
    is_required : new FormControl('', [Validators.required]),
    license_type_id: new FormControl('', Validators.required)
  });

  /**
   * helper function to extract data since
   * we are not using a type checker in the request
   * @returns Observable
   *
   * @param res
   */
  private static extractData(res: Response) {
    return res || {};
  }

  /**
   * get the list of all documents from the api
   * @param null null
   * @returns Observable
   *
   */
  get(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT).pipe(
      map(DocumentService.extractData));
  }

  getRemainingDocs(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT2).pipe(
      map(DocumentService.extractData));
  }

  getRenewalDocs(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT3).pipe(
      map(DocumentService.extractData));
  }
  
  /**
   * get a document by from the api
   * @param id : string
   * @returns Observable
   *
   */
  find(id): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + '/' + id).pipe(
      map(DocumentService.extractData));
  }

  /**
   * create a document
   * @param document : Object
   * @returns Observable
   *
   */
  create(document: any): Observable<any> {
    return this.httpClient.post<any>(this.API_ENDPOINT, document)
    .pipe(tap((document) => console.log(`added document w/ name=${document}`)),
      catchError(this.handleError<any>('create document'))
    );
  }

  /**
   * delete a document
   * @param id : string
   * @returns Observable
   *
   */
  delete(id): Observable<any> {
    return this.httpClient.delete<any>(this.API_ENDPOINT + '/' + id).pipe(
      tap(_ => console.log(`deleted document id=${id}`)),
      catchError(this.handleError<any>('delete document'))
    );
  }

  /**
   * update a document
   * @param document : Object
   * @returns Observable
   *
   */
  update(document): Observable<any> {
    console.log(document);
    return this.httpClient.put(this.API_ENDPOINT + '/' + document.id, document)
    .pipe(tap(response => console.log(`updated document id=${document.id}`)),
      catchError(this.handleError<any>('update document'))
    );
  }

  getAddPrincipalCompanyDocs(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT4).pipe(
      map(DocumentService.extractData));
  }

  getremainingPrincipalCompanyDocs(uid: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT6+"/"+uid).pipe(
      map(DocumentService.extractData));
  }

  getChangePrincipalCompanyDocs(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT4).pipe(
      map(DocumentService.extractData));
  }

  createDocumentControl(data: any): Observable<any> {
    return this.httpClient.post<any>(this.API_ENDPOINT+"-control", data);
  }

  private getValue() {
    return 0;
  }

  /**
   * Error handler function that handles all the errors
   * @returns Observable
   *
   * @param operation
   * @param result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for document consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  initializeFormGroup() {
    return this.form.patchValue({
      id        : '',
      document_name : '',
      player_category_id : '',
      is_business_licence : '',
      document_information : '',
      is_active : '',
      is_required : ''
    });
  }

  populateForm(data) {
    this.form.patchValue(data);
  }

  compareObjects(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }
}
